import React, { useEffect, useState } from "react";
import "animate.css/animate.min.css";
import Head from "../Head/Head";
import Collaboration from "../Collaboration/Collaboration";
import Approach from "../Approach/Approach";
import Services from "../Services/Services";
import OurTeam from "../OurTeam/OurTeam";
import SuccessStory from "../SuccessStory/SuccessStory";
import Blogs from "../Blogs/Blogs";
import Contacts from "../Contacts/Contacts";
import Footer from "../Footer/Footer";
import UseScrolleffect from "../../CustomHooks/UseScrolleffect";
import loadjs from "loadjs";
import { useHistory, useLocation } from "react-router-dom";
import smoothscroll from "smoothscroll-polyfill";
import {Helmet} from "react-helmet"
import OurAssociations from "../OurAssosiations/OurAssociations";
import Servicesv2 from "../Services/Services copy"
import Servicesv3 from "../Services/Servicesv2"
import Offerings from "../Offerings/Offerings";
import useWindowSize from "use-window-size-v2";
import "./home.css"

function Home(props) {

	const {width,height} = useWindowSize(0)

	UseScrolleffect();
	const location = useLocation();
	const [hash, sethash] = useState(null);
	const hashScroll = () => {
		if (hash !== "") {
			// Push onto callback queue so it runs after the DOM is updated,
			// this is required when navigating from a different page so that
			// the element is rendered on the page before trying to getElementById.
			setTimeout(() => {
				const id = hash.replace("#", "");
				const element = document.getElementById(id);
				if (element) {
					const yCoordinate =
						element.getBoundingClientRect().top + window.scrollY;
					const yOffset = -80;
					window.scrollTo({
						top: yCoordinate + yOffset,
						behavior: "smooth",
					});
				}
			}, 200);
		}
	};
	useEffect(() => {
		loadjs.reset();
		loadjs("/assets/js/script.js", () => {});
		sethash(location.hash);
		hashScroll();
	}, [hash, location]);

	return (
		<>
		   <Helmet>
				<meta title="Tudo Technologies - Best Mobile App and Website Development Company in Bangalore India with 15 years of experience in web Android and iOS App Development. "
        		name="description"
				content="IT Services Company in Bangalore, India, Software companies in Bangalore, iOS/Android development, Software Development, UI/UX Design, D2C app development, Platform development.
				We are dominant IT services company in Bangalore. Which acts as a technology associate partner for you. Avail our custom web development, app solution, iOS/Android Developments and digital marketing solutions that reach to your audience.
				Tudo Technologies is an IT service provider recognized globally as a technology expert with offices in Toronto, Canada, San Francisco, California, USA, Sydney, Australia, Auckland, New Zealand, UAE & Bangalore, India. 
				We offer services in key areas of enterprise mobile app development, customer relationship management (CRM), enterprise content management solutions(CMS), DevOps and software product engineering (SPE). Connect with us and let's discuss business!"
				/>
				<meta name="keywords" content="app development company, top app development companies, app development companies, top app development company, mobile app development company, mobile app development companies, bangalore, 
					bengaluru, dubai, lagos, Abu Dhabi, san francisco, toronto, auckland, sydney, glasgow, UK, USA, Canada, New Zealand, Australia, UAE, India, Nigeria, best app development companies, best app development company"/>
	  		</Helmet>
			
			<Head />
			<Collaboration />
			<Approach />

			{
				width >= 800 ?

				<div className="service-team-container">
					<div className="service-gradient">
						<img src="/assets/img/service-gradient.png" alt="" />
					</div>
					<Servicesv3 />
					<OurTeam />
				</div>

				:

				<>
					<Servicesv2/>
					<OurTeam />
				</>

			}

			<Offerings/>
			<OurAssociations/>
			<SuccessStory />
			<Blogs />
			<Contacts />
		</>
	);
}

export default Home;
