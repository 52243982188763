import React from "react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import "./MobileNav.css";
import { Helmet } from "react-helmet";


export default function MobileNav() {
	const HandleMobileNav = () => {
		console.log(document.getElementById("mobileNav"));
		document.getElementById("mobileNav").classList.toggle("d-none");
	};
	return (
		<>
			<Helmet>
				<meta title="Tudo Technologies - Best Mobile App and Website Development Company in Bangalore India with 15 years of experience in web Android and iOS App Development. "
        		name="description"
				content="IT Services Company in Bangalore, India, Software companies in Bangalore, iOS/Android development, Software Development, UI/UX Design, D2C app development, Platform development.
				We are dominant IT services company in Bangalore. Which acts as a technology associate partner for you. Avail our custom web development, app solution, iOS/Android Developments and digital marketing solutions that reach to your audience.
				Tudo Technologies is an IT service provider recognized globally as a technology expert with offices in Toronto, Canada, San Francisco, California, USA, Sydney, Australia, Auckland, New Zealand, UAE & Bangalore, India. 
				We offer services in key areas of enterprise mobile app development, customer relationship management (CRM), enterprise content management solutions(CMS), DevOps and software product engineering (SPE). Connect with us and let's discuss business!"
				/>
				<meta name="keywords" content="app development company, top app development companies, app development companies, top app development company, mobile app development company, mobile app development companies, bangalore, 
				bengaluru, dubai, lagos, Abu Dhabi, san francisco, toronto, auckland, sydney, glasgow, UK, USA, Canada, New Zealand, Australia, UAE, India, Nigeria, best app development companies, best app development company"/>
	  	</Helmet>
		
		
		<div
			id='mobileNav'
			className=' d-none jutify-content-center align-items-center vh-100 vw-100 position-fixed'
			style={{ background: "black", top: 0, left: 0, zIndex: 500 }}>
			<div className='d-flex justify-content-end p-4'>
				<span
					onClick={() => HandleMobileNav()}
					style={{ cursor: "pointer" }}>
					<i className='fas fa-times fa-2x'></i>
				</span>
			</div>
			<div className='d-flex flex-column justify-content-start align-items-center w-100 h-100'>
				<Link
					onClick={() => HandleMobileNav()}
					to='/'
					className='logo d-flex justify-content-center my-3'>
					<img
						src='/assets/img/logo.png'
						alt=''
						className='img-fluid'
					/>
				</Link>
				<div className='navLinks d-flex flex-column align-items-center justify-content-center w-100'>
					<Link
						onClick={() => HandleMobileNav()}
						className='my-3'
						to='/#services'>
						Services
					</Link>
					<Link
						onClick={() => HandleMobileNav()}
						className='my-3'
						to='/ourworks'>
						Our Works
					</Link>
					<HashLink
						onClick={() => HandleMobileNav()}
						className='my-3'
						to='/#blogs'>
						Blogs
					</HashLink>
					<HashLink
						onClick={() => HandleMobileNav()}
						className='my-3'
						to='/#contact'>
						Contact
					</HashLink>
				</div>
				<div className='d-flex justify-content-center w-100 mt-4'>
					<span className=''>
						<a target="_blank" href='tel:+919535383084'>
							<i className='fas fa-phone-alt fa-2x'></i>
						</a>
					</span>
					<span className='mx-4'>
						<a target="_blank" href='mailto:arjun@tudotechnologies.com'>
							<i className='fas fa-envelope fa-2x'></i>
						</a>
					</span>
					
					<span className=''>
						<a target="_blank" href='https://wa.me/+919535383084?text=I%27ve%20been%20in%20redirected%20from%20your%20website.'>
							<i className='fa fa-whatsapp fa-2x'></i>
						</a>
					</span>
				</div>
			</div>
		</div>
		</>
	);
}
