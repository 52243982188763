import React, { useEffect } from "react";
import $ from "jquery";
import Slider from "react-slick";

export default function Services() {
  //   useEffect(() => {

  //   }, []);
  return (
    <div className='fadeEntry animate__animated '>
      <section className='services'>
        <div className='container'>
          <div className='col-lg-12' style={{ margin: "0 auto" }}>
            <div className='row d-flex align-items-center'>
              <div className='col-lg-6 d-flex flex-column justify-content-end'>
                <h1 className='services-title'>
                  Our Services<span className='cyan'>.</span>
                </h1>
                <p>
                  We work small, but we think big. We wrestle with business
                  challenges, discover new opportunities, and provide innovative
                  solutions that will help you define and refine your product
                  ideas, design them, develop web and mobile-app. Ensuring its
                  Scalable, Reliable, Easy Usable and Secure.{" "}
                </p>
              </div>
              <div className='col-lg-6 d-flex justify-content-end services-slider'>
                <div className='container'>
                  <div className='pagination' id='slick-wrapper'>
                    <div className='slick-item'>
                      <span className='pagination-item | animate'>
                        Full Stack Development
                      </span>
                      <p>
                        With the experience of building companies from the idea
                        stage to multiple rounds of funding. We will implement
                        all the development needs of your brand.
                      </p>
                    </div>
                    <div className='slick-item'>
                      <span className='pagination-item | animate'>UX/UI</span>
                      <p className='pb-lg-0 pb-4'>
                        We will create the best user experience for applications
                        and run across multiple levels of user validations to
                        ensure the end-user has the best experience of your
                        platform.
                      </p>
                    </div>
                    <div className='slick-item'>
                      <span className='pagination-item | animate'>
                        Backend Development
                      </span>
                      <p>
                        With over 50 years of cumulative experience in backend
                        development for multiple organizations. We will develop
                        the backend structure strongly focused on flexibility,
                        scalability, and security.
                      </p>
                    </div>
                    <div className='slick-item'>
                      <span className='pagination-item | animate'>
                        Frontend Development
                      </span>
                      <p>
                        Looking for a team that integrates your backend business
                        logic into user-centric platforms? We will help you
                        create the right designs, implement the APIs and take
                        your dream to users.
                      </p>
                    </div>
                    <div className='slick-item'>
                      <span className='pagination-item | animate'>
                        Hybrid App Development
                      </span>
                      <p>
                        Developing an app that does not feature intensive? We
                        develop apps using cross-platform frameworks like
                        include Flutter, React, Ionic and Xamarin.
                      </p>
                    </div>
                    <div className='slick-item'>
                      <span className='pagination-item | animate'>
                        Native App Development
                      </span>
                      <p>
                        Hybrid app development doesn't fit your need? We build
                        the app on native Android and iOS to ensure your app can
                        provide the best user experience.
                      </p>
                    </div>
                    <div className='slick-item'>
                      <span className='pagination-item | animate'>
                        Ideation
                      </span>
                      <p className='pb-lg-2 pb-5'>
                        We believe in LEAN product development. We ideate with
                        you to ensure you have a scalable product with the most
                        essential functionalities.
                      </p>
                    </div>
                    <div className='slick-item'>
                      <span className='pagination-item | animate'>
                        Product Validation
                      </span>
                      <p>
                        Through our experienced team and network connectivity,
                        we validated that the end product satisfies its
                        stakeholder (customer and other interested parties)
                        expectations (MOEs) within the intended operational
                        environments.
                      </p>
                    </div>
                    <div className='slick-item'>
                      <span className='pagination-item | animate'>
                        Business Consulting
                      </span>
                      <p className='pb-lg-2 pb-5'>
                        With our experienced business consultants, we can help
                        you validate the best way to progress your business.
                      </p>
                    </div>
                    <div className='slick-item'>
                      <span className='pagination-item | animate'>
                        Content Writing
                      </span>
                      <p className='pb-lg-2 pb-5'>
                        Our Content writers create print and digital content for
                        companies that provide information or showcase the
                        products or services they offer.
                      </p>
                    </div>
                    <div className='slick-item'>
                      <span className='pagination-item | animate'>
                        Digital Marketing
                      </span>
                      <p>
                        Our marketing, content, and design team will ensure the
                        quality delivery of your brand promotion through digital
                        channels such as search engines, websites, social media,
                        email, and mobile apps.
                      </p>
                    </div>
                    <div className='slick-item'>
                      <span className='pagination-item | animate'>
                        PR Services
                      </span>
                      <p className='pb-lg-2 pb-5'>
                        Our PR team with their strategic tie-ups will promote
                        companies via editorial coverage and influencer
                        marketing.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!--=========== End Services Section ===========--> */}
    </div>
  );
}
