import React from "react";
import { Helmet } from "react-helmet";

export default function Contacts() {
	return (
		<>
		 	<Helmet>
				<meta title="Tudo Technologies - Best Mobile App and Website Development Company in Bangalore India with 15 years of experience in web Android and iOS App Development. "
        		name="description"
				content="IT Services Company in Bangalore, India, Software companies in Bangalore, iOS/Android development, Software Development, UI/UX Design, D2C app development, Platform development.
				We are dominant IT services company in Bangalore. Which acts as a technology associate partner for you. Avail our custom web development, app solution, iOS/Android Developments and digital marketing solutions that reach to your audience.
				Tudo Technologies is an IT service provider recognized globally as a technology expert with offices in Toronto, Canada, San Francisco, California, USA, Sydney, Australia, Auckland, New Zealand, UAE & Bangalore, India. 
				We offer services in key areas of enterprise mobile app development, customer relationship management (CRM), enterprise content management solutions(CMS), DevOps and software product engineering (SPE). Connect with us and let's discuss business!"
				/>
				<meta name="keywords" content="app development company, top app development companies, app development companies, top app development company, mobile app development company, mobile app development companies, bangalore, 
				bengaluru, dubai, lagos, Abu Dhabi, san francisco, toronto, auckland, sydney, glasgow, UK, USA, Canada, New Zealand, Australia, UAE, India, Nigeria, best app development companies, best app development company"/>
	  	</Helmet>
	
		<section className='contact' id='contact'>
			<div className='container'>
				<div className='col-lg-12' style={{ margin: "0 auto" }}>
					<div className='row contact-content'>
						<div className='col-lg-5'>
							<h1 className='contact-title'>
								Contact Us<span className='cyan'>.</span>
							</h1>
							<div>
								<div className='fw-bold'>
									<div className='d-flex align-items-center'>
										<span style={{ fontSize: "1.953rem" }}>
											Arjun G N
										</span>

										<span
											className='ms-3 border p-2 me-3 d-inline-flex justify-content-center'
											style={{ width: "2.5rem" }}>
											<a
												target='_blank'
												href='https://www.linkedin.com/in/arjungn'>
												<i className='fab fa-linkedin-in'></i>
											</a>
										</span>
									</div>
									<h5 className='my-2'>
										<a
											target='_blank'
											href='mailto:arjun@tudotechnologies.com'>
											<i className='fal fa-envelope me-3'></i>
											arjun@tudotechnologies.com
										</a>
									</h5>
									<h5 className='mt-3'>
										<a
											target='_blank'
											href='tel:+919535383084'>
											<i className='fal fa-phone-alt me-3'></i>
											+91 9535383084
										</a>
									</h5>
								</div>


								{/* <div className='fw-bold mt-4'>
									<div className='d-flex align-items-center'>
										<span style={{ fontSize: "1.953rem" }}>
											Nikhil
										</span>

										<span
											className='ms-3 border p-2 me-3 d-inline-flex justify-content-center'
											style={{ width: "2.5rem" }}>
											<a
												target='_blank'
												href='https://www.linkedin.com/in/nikhil-meda-radha-krishna-23530712'>
												<i className='fab fa-linkedin-in'></i>
											</a>
										</span>
									</div>
									<h5 className='my-2'>
										<a
											target='_blank'
											href='mailto:nikhil@tudotechnologies.com'>
											<i className='fal fa-envelope me-3'></i>
											nikhil@tudotechnologies.com
										</a>
									</h5>
									<h5 className='mt-3'>
										<a
											target='_blank'
											href='tel:+919886493060'>
											<i className='fal fa-phone-alt me-3'></i>
											+61 402 491 322
										</a>
									</h5>
								</div> */}




							</div>

							<h5 className='social-icons d-none d-lg-block mt-5'>
								<span>
									<span
										className='border p-2 me-3 d-inline-flex justify-content-center'
										style={{ width: "2.5rem" }}>
										<a target='_blank' href='https://www.linkedin.com/company/tudo-technologies/'>
											<i className='fab fa-linkedin-in'></i>
										</a>
									</span>
									<span
										className='border p-2 me-3 d-inline-flex justify-content-center'
										style={{ width: "2.5rem" }}>
										<a target='_blank' href='mailto:connect@tudotechnologies.com'>
											<i className='fal fa-envelope'></i>
										</a>
									</span>
									{/* <span
                      className='border p-2 me-3 d-inline-flex justify-content-center'
                      style={{ width: "2.5rem" }}>
                      <a target='_blank' href='#'>
                        <i className='fab fa-twitter'></i>
                      </a>
                    </span> */}
									<span
										className='border p-2 me-3 d-inline-flex justify-content-center'
										style={{ width: "2.5rem" }}>
										<a
											target='_blank'
											href='https://wa.me/+919535383084?text=I%27ve%20been%20in%20redirected%20from%20your%20website.'>
											<i className='fab fa-whatsapp'></i>
										</a>
									</span>
								</span>
							</h5>
						</div>
						<div className='col-lg-7 countries'>
							<object
								type='image/svg+xml'
								data='assets/img/countries.svg'></object>
						</div>
					</div>
				</div>
			</div>
		</section>
		</>
	);
}
