import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import NavBar from '../Head/NavBar'
import "./ourWorksv2.css"

const OurWorksv2 = () => {

    const [sectorTags,setSectorTags] = useState([
        {
            "name":"E-learning  & Entertainment",
            "img":"/assets/img/e-learning.svg"
        },
        {
            "name":"Retail",
            "img":"/assets/img/retail.svg"
        },
        {
            "name":"Hospitality & Real estate",
            "img":"/assets/img/hospitality.svg"
        },
        {
            "name":"Manufacturing",
            "img":"/assets/img/manufacturing.svg"
        },
        {
            "name":"Healthcare",
            "img":"/assets/img/healthcare.svg"
        },
        {
            "name":"Mobility",
            "img":"/assets/img/mobility.svg"
        },
    ])

    const [ourworksData, setOurworksData] = useState([]);
    const [ourworksDataCopy, setOurworksDataCopy] = useState([]);

    useEffect(() => {
        fetch("/ourworks.json")
        .then((res) => res.json())
        .then((result) => {
            console.log(result);
            setOurworksDataCopy(result)
            setOurworksData(result.slice(0,6));
            
        });
    }, []);

  return (
    <>
        <Helmet>
				<meta title="Tudo Technologies - Best Mobile App and Website Development Company in Bangalore India with 15 years of experience in web Android and iOS App Development. "
                name="description"
				content="IT Services Company in Bangalore, India, Software companies in Bangalore, iOS/Android development, Software Development, UI/UX Design, D2C app development, Platform development.
				We are dominant IT services company in Bangalore. Which acts as a technology associate partner for you. Avail our custom web development, app solution, iOS/Android Developments and digital marketing solutions that reach to your audience.
				Tudo Technologies is an IT service provider recognized globally as a technology expert with offices in Toronto, Canada, San Francisco, California, USA, Sydney, Australia, Auckland, New Zealand, UAE & Bangalore, India. 
				We offer services in key areas of enterprise mobile app development, customer relationship management (CRM), enterprise content management solutions(CMS), DevOps and software product engineering (SPE). Connect with us and let's discuss business!"
				/>
				<meta name="keywords" content="app development company, top app development companies, app development companies, top app development company, mobile app development company, mobile app development companies, bangalore, 
				bengaluru, dubai, lagos, Abu Dhabi, san francisco, toronto, auckland, sydney, glasgow, UK, USA, Canada, New Zealand, Australia, UAE, India, Nigeria, best app development companies, best app development company"/>
	  	</Helmet>
        <NavBar />
        <img className='gradientDesign' src="/assets/img/gradientDesign.png" alt="" />
        
        <div className='OurWorksv2 container'>
            <h1 className='ourWorks-header fw-bold'>We have curated experiences<br/> for over 100+ projects</h1>
            <p className='mt-4'>We have designed and developed technology-enhanced business solutions across diverse sectors<br/> including:</p>


            <div className="sectorTagsContainer mt-4">
                {
                   sectorTags && sectorTags.map((item) => (
                    <div className='sectorTags'>
                        <img src={item.img} alt="" />
                        <span>{item.name}</span>
                    </div>
                   )) 
                }
            </div>

            <div className="projectList">
                {
                    ourworksData.map((item) => (
                        
                        <div className='project-card'>
                            <div className='project-card-child'>
                                <Link to={`/ourworks/${item.id}`}>
                                <img src={item.thumbnil} alt="" />
                                </Link>
                            </div>
                        </div>
                    ))
                }
            </div>

            <div className="viewMoreBtn" onClick={() => {
					ourworksData.length <= 6 ? setOurworksData(ourworksDataCopy) : setOurworksData(ourworksDataCopy.slice(0,6)) 

				}}>{
                        ourworksData.length <= 6 ? 
                        <>
                        <span>View More</span>
                        <img src="/assets/img/down-arrow.svg" alt="" />
                        </> : 
                        <>
                        <span>View Less</span>
                        <img className='up-arrow' src="/assets/img/down-arrow.svg" alt="" />
                        </>
                    }
            </div>
        </div>
    </>
  )
}

export default OurWorksv2